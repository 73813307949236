"use client";
import { Controller, useForm } from "react-hook-form";

import {
  PREREGISTATION_API,
  PreregistrationRequest,
  PreregistrationResponse,
} from "@ctv/app/[locale]/register/apis";
import { useClientApi, useContentResource } from "@ctv/core";
import { InputFieldSeo, TextSeo, Token, ViewSeo } from "@ctv/shared-core/src";
import TrackingButtonSeo from "@ctv/shared/components/Tracking/TrackingButtonSeo";
import { trackImInterestedButtonClick } from "@ctv/shared/tracking/landing-page/buttonClick";
import { isEmail, maxLength, required } from "@ctv/shared/utils/validator";

import ContactMail from "@traveloka/icon-kit-web/react/IcContactMail";

import cssStyles from "./RegistrationForm.module.css";

type EmailFormValue = { email: string };

export default function RegistrationForm() {
  const cr = useContentResource().CorporateRevampHomePage;
  const registerCR = useContentResource().CorporateRegistration;
  const methods = useForm<EmailFormValue>();
  const registerAPI = useClientApi<
    PreregistrationResponse,
    PreregistrationRequest
  >({
    domain: "management",
    method: "post",
    path: PREREGISTATION_API,
  });

  async function handleSubmit(data: EmailFormValue) {
    const payload = {
      picEmail: data.email,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const res = await registerAPI(payload);
    if (!res.success) return;
    res.data.status === "EMAIL_HAS_REGISTERED" &&
      methods.setError("email", { message: registerCR.emailHasRegistered });
  }
  return (
    <ViewSeo spacing="xxs">
      <ViewSeo spacing="m" align="center" row>
        <Controller
          control={methods.control}
          name="email"
          rules={{
            validate(v) {
              if (required(v) === false) {
                return registerCR.picEmailRequired;
              } else if (maxLength(v, 256) === false) {
                return registerCR.picEmailMaxLength;
              } else if (isEmail(v) === false) {
                return registerCR.picEmailFormat;
              }

              return;
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <ViewSeo row spacing="m">
                  <InputFieldSeo
                    className={cssStyles.input}
                    startIcon={<ContactMail color={Token.color.bluePrimary} />}
                    placeholder={cr.emailPlaceholder}
                    error={error?.message}
                    onChange={onChange}
                  />
                  <TrackingButtonSeo
                    className={cssStyles.ctaButton}
                    size="medium"
                    variant="main-cta"
                    text={cr.interestedButtonText}
                    onPress={methods.handleSubmit(handleSubmit)}
                    track={trackImInterestedButtonClick(value)}
                  />
                </ViewSeo>
              </>
            );
          }}
        />
      </ViewSeo>
      <TextSeo variant="ui-tiny" ink="white-primary">
        {cr.emailLabel}
      </TextSeo>
    </ViewSeo>
  );
}
