"use client";
import { useEffect } from "react";

import { LandingPageProps } from "@ctv/app/[locale]/page";
import { useLocalizedRouterServer } from "@ctv/shared-core/src";
import { useAuth, useTracker } from "@ctv/core";
import {
  event,
  landingPageTracking as tracking,
} from "@ctv/shared/tracking/landing-page";
import { UrsulaTrackingSensor as Sensor } from "@ctv/shared/tracking/landing-page/sensor";

import ArrangeMeetingFloatingButton from "./components/ArrangeMeetingFloatingButton/ArrangeMeetingFloatingButton";
import Benefit from "./components/Benefit/Benefit";
import BenefitTab from "./components/BenefitTab/BenefitTab";
import BusinessNumber from "./components/BusinessNumber/BusinessNumber";
import CompanyMarquee from "./components/CompanyMarquee/CompanyMarquee";
import ConanArticles from "./components/ConanArticles/ConanArticles";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";

export default function PreLogin(props: LandingPageProps) {
  const auth = useAuth();
  const router = useLocalizedRouterServer();

  useEffect(() => {
    if (auth.isAuthenticated) {
      router.replace("/home");
    }
  }, [auth.isAuthenticated, router]);

  if (auth.isAuthenticated) {
    return null;
  }

  return <PreLoginContent {...props} />;
}
function PreLoginContent(props: LandingPageProps) {
  const { recentArticles } = props;

  const track = useTracker();

  useEffect(() => {
    track(event.CORPORATE_TRAVEL, tracking.LOAD_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  }, []);

  return (
    <>
      <Header />
      <Hero />
      <CompanyMarquee />
      <Sensor data={tracking.USP_SECTION} event={event.CORPORATE_TRAVEL}>
        <Benefit />
      </Sensor>
      <Sensor data={tracking.FEATURE_SECTION} event={event.CORPORATE_TRAVEL}>
        <BenefitTab />
      </Sensor>
      {/* Sensor component inside the ConanArticles component */}
      <ConanArticles recentArticles={recentArticles} />
      <Sensor data={tracking.VIDEO_SECTION} event={event.CORPORATE_TRAVEL}>
        <BusinessNumber />
      </Sensor>
      <Sensor data={tracking.FOOTER_SECTION} event={event.CORPORATE_TRAVEL}>
        <Footer />
      </Sensor>
      <ArrangeMeetingFloatingButton />
    </>
  );
}
